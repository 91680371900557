<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑       永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2021-11-29 10:33:57
 * @LastEditTime: 2021-12-01 19:49:50
 * @Description: 再下订单
 * @Param: contract_number【合同编号】
 * @FilePath: \JT_Web_test\src\views\salesBusiness\retailBusiness\retailBusinessForm_orderAgain.vue
 -->
<template>
    <div class="retailBusinessForm_orderAgain" v-loading="loading">
        <div class="pageContent" :class="{'fomeSee': isSee}">
            <div class="fomeStyle_dy">
                <table>
                    <tbody>
                        <tr>
                            <th>订单编号：</th>
                            <td>
                                <el-input v-model="formData.order_number" disabled placeholder="系统生成"></el-input>
                            </td>
                            <th>登记时间：</th>
                            <td>
                                <el-date-picker
                                    disabled
                                    v-model="formData.bill_time"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th>创建人：</th>
                            <td>
                                <el-input v-model="formData.creator_name" disabled placeholder="请输入内容"></el-input>
                            </td>
                            <th>联系电话：</th>
                            <td>
                                <el-input v-model="formData.creator_phone" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <th colspan="4">
                                合同信息
                            </th>
                        </tr>
                        <tr>
                            <th>合同编号：</th>
                            <td>
                                <el-input
                                    readonly
                                    v-model="formData.contract_number"
                                    class="input-with-select"
                                    disabled
                                ></el-input>
                            </td>
                            <th>合同类型：</th>
                            <td>
                                <el-input
                                    v-model="formData.contract_typename"
                                    readonly
                                    disabled
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>销售方式：</th>
                            <td>
                                <el-input
                                    v-model="formData.sale_method_name"
                                    readonly
                                    disabled
                                ></el-input>
                            </td>
                            <th>销售区域：</th>
                            <td>
                                <el-input
                                    v-model="formData.sale_area_name"
                                    readonly
                                    disabled
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>客户名称：</th>
                            <td>
                                <el-input
                                    v-model="formData.customer_name"
                                    disabled
                                    placeholder="请输入内容"
                                ></el-input>
                            </td>
                            <th>统一社会信用代码或身份证：</th>
                            <td>
                                <el-input
                                    v-model="formData.customer_xydm"
                                    disabled
                                    placeholder="请输入内容"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th> 工程名称：</th>
                            <td>
                                <el-input
                                    v-model="formData.project_name"
                                    readonly
                                    disabled
                                ></el-input>
                            </td>
                            <th>工程类型：</th>
                            <td>
                                <el-input
                                    v-model="formData.project_type_name"
                                    readonly
                                    disabled
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工程地址：</th>
                            <td colspan="3">
                                <el-input
                                    v-model="formData.project_address"
                                    readonly
                                    disabled
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                当前收货地址：
                            </th>
                            <td colspan="3">
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.project_address1"
                                    :disabled="isSee"
                                    class="input-with-select"
                                >
                                    <el-button
                                        slot="append"
                                        class="iconfont iconlocation1"
                                        @click="openDrawFence"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                        </tr>
                        <tr v-if="formData.construction_method == 2">
                            <th>施工单位：</th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择施工单位"
                                    v-model="formData.sgdw_name"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectConstructionUnit"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                            <th>统一社会信用代码：</th>
                            <td>
                                <el-input
                                    v-model="formData.sgdw_code"
                                    readonly
                                    :disabled="isSee"
                                    placeholder="请选择施工单位"
                                ></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <th colspan="4">
                                工程信息
                            </th>
                        </tr>
                        <tr>
                            <th>标段：</th>
                            <td colspan="3">
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.section"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>相关单体：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.monomer_name"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                            <th>施工楼层：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.cons_floor"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工程结构：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.place_category_name"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                            <th>施工部位：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.place_name"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>施工部位描述：</th>
                            <td colspan="3">
                                <el-input v-model="formData.place_detail" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>强度等级：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.strength_grade_name"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                            <th>附加型号：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.special_require_name"
                                    disabled
                                    class="input-with-select"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                订货方量：
                            </th>
                            <td>
                                <el-input v-model="formData.quantity" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                            <th class="iconfont iconbitian">
                                要求到货时间：
                            </th>
                            <td>
                                <el-date-picker
                                    v-model="formData.arrival_time"
                                    type="datetime"
                                    placeholder="选择日期时间"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    :disabled="isSee"
                                >
                                </el-date-picker>
                            </td>
                        </tr>
                        <tr>
                            <th class="iconfont iconbitian">
                                运输方式：
                            </th>
                            <td>
                                <el-select
                                    v-model="formData.transport"
                                    placeholder="请选择运输方式"
                                    @change="selectTransportColumns"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in transportColumns"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <th class="iconfont iconbitian">
                                浇筑方式：
                            </th>
                            <td>
                                <el-select
                                    v-model="formData.pouring"
                                    placeholder="请选择浇筑方式"
                                    @change="selectPouringOriginal"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in pouringOriginal"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <th>泵车类型：</th>
                            <td>
                                <el-select
                                    v-model="formData.pump_truck"
                                    placeholder="请选择泵车类型"
                                    @change="selectPumpTruckColumns"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in pumpTruckColumns"
                                        :key="item.code"
                                        :label="item.name"
                                        :value="item.code"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                            <th class="iconfont iconbitian">
                                供货站点：
                            </th>
                            <td>
                                <el-select
                                    v-model="formData.station"
                                    placeholder="请选择供货站点"
                                    @change="selectSupplySite"
                                    :disabled="isSee"
                                >
                                    <el-option
                                        v-for="item in supplySite"
                                        :key="item.station_id"
                                        :label="item.name"
                                        :value="item.station_id"
                                    >
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <th>坍落度：</th>
                            <td class="slump">
                                <el-input v-model="formData.slump" placeholder="请输入内容" :disabled="isSee"></el-input>
                                <span>—</span>
                                <el-input v-model="formData.slump_name" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                            <th>运距：</th>
                            <td>
                                <el-input v-model="formData.haul_distance" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工地要料人：</th>
                            <td>
                                <el-input
                                    readonly
                                    placeholder="请选择工地要料人"
                                    v-model="formData.gdylr"
                                    class="input-with-select"
                                    :disabled="isSee"
                                >
                                    <el-button
                                        slot="append"
                                        icon="iconfont icon009"
                                        @click="selectMaterialPerson"
                                        v-if="!isSee"
                                    ></el-button>
                                </el-input>
                            </td>
                            <th>联系电话：</th>
                            <td>
                                <el-input
                                    v-model="formData.gdylr_phone"
                                    readonly
                                    placeholder="请选择工地要料人"
                                    :disabled="isSee"
                                ></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>现场联系人：</th>
                            <td>
                                <el-input
                                    placeholder="请输入内容"
                                    v-model="formData.xclxr"
                                    class="input-with-select"
                                    :disabled="isSee"
                                ></el-input>
                            </td>
                            <th>联系电话：</th>
                            <td>
                                <el-input v-model="formData.xclxr_phone" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>工地限高(米)：</th>
                            <td>
                                <el-input v-model="formData.limited_height" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                            <th>工地限方：</th>
                            <td>
                                <el-input v-model="formData.limited_volume" placeholder="请输入内容" :disabled="isSee"></el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>备注：</th>
                            <td colspan="3">
                                <el-input
                                    type="textarea"
                                    placeholder="请输入内容"
                                    v-model="formData.remarks"
                                    :disabled="isSee"
                                >
                                </el-input>
                            </td>
                        </tr>
                        <tr>
                            <th>价格：</th>
                            <td colspan="3">
                                <el-input v-model="formData.price_remark" disabled></el-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <footer v-if="!isSee">
            <el-button type="primary" @click="submitClick" :loading="submitLoading">
                提交
            </el-button>
            <el-button @click="close">
                取消
            </el-button>
        </footer>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    name: 'retail-business-form-order-again',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            loading: false,
            submitLoading: false,
            formData: {},
            // 必填项
            formRequired: {
                quantity: {
                    message: '请输入订货方量',
                },
                arrival_time: {
                    message: '请选择要求到货时间',
                },
                transport: {
                    message: '请选择运输方式',
                },
                pouring: {
                    message: '请选择浇筑方式',
                },
                station: {
                    message: '请选择供货站点',
                },
            },
            result: [],
            fence_point: '',
            associated: false,
            // 运输方式下拉数据
            transportColumns: [],
            // 浇筑方式下拉数据
            pouringOriginal: [],
            // 泵车类型下拉数据
            pumpTruckColumns: [],
            // 供货站点下拉数据
            supplySite: [],
            // 选择合同是否关联经销商合同
            isRelatedContract: false,
            // 查看场景
            isSee: false,
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initPage();
    },
    destroyed() {},
    methods: {
        async initPage() {
            this.loading = true;
            this.formData = await this.getFormData(this.extr.contract_number);

            if (!this.formData.poid) {
                this.formData.order_number = await this.getAutoCode();
            }
            this.formData.bill_time = util.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
            this.formData.creator_name = this.$takeTokenParameters('Name');
            this.formData.creator_phone = this.$takeTokenParameters('MobilePhone');

            // 获取-运输方式
            this.transportColumns = await this.getPlaceCategory('304-YS0');
            if (this.transportColumns.length > 0) {
                this.formData.transport = this.transportColumns[1].code;
                this.formData.transport_name = this.transportColumns[1].name;// 默认第二个
            }

            // 获取-浇筑方式
            this.pouringOriginal = await this.getPlaceCategory('302-JZ0');

            // 获取-泵车类型
            this.pumpTruckColumns = await this.getPlaceCategory('303-BS0');

            // 获取-供应站点
            this.supplySite = await this.getStationList();

            if (this.formData.sale_method_code === '2') {
                this.getAffiliatedDealers()
                    .then( isAffiliatedDealers => {
                        if (isAffiliatedDealers === 'ok') {
                            this.isRelatedContract = true;
                        }
                    });
            } else {
                this.isRelatedContract = true;
            }
            if (this.extr?.order_number) {
                this.formData = await this.getInitFormData(this.extr.order_number);
            }
            this.loading = false;
        },
        close() {
            this.$parent.hide();
        },

        // 获取流程模板信息
        getBusinessInstance() {
            this.$axios
                .get('/interfaceApi/basicplatform/simpleflow/businessinstance/templateinfo/HTPS-CXFH')
                .then(res => {
                    if (res) {
                        this.businessInfo = res;
                        this.formData.business_instance_id = this.businessInfo.BusinessInstanceId;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 当前收货地址定位
        openDrawFence() {
            const x = this.formData.project_longitude1;
            const y = this.formData.project_latitude1;
            const address = this.formData.project_address1;
            this.$toast({
                title: true,
                text: '当前收货地址',
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'productionManagement/logisticsMonitoring/drawElectronicFence',
                viewPosition: 'view',
                extr: {
                    closeBtn: {},
                    position: [x, y],
                    fence_point: this.fence_point,
                    address: address,
                    sureCallback: this.drawCallBack,
                    onlyPoint: true,
                },
            });
        },

        // 当前收货地址选择回调
        drawCallBack(overlays, position, address) {
            this.formData.project_address1 = address;
            this.formData.project_longitude1 = position[0];
            this.formData.project_latitude1 = position[1];
        },

        // 选择施工单位
        selectConstructionUnit() {
            if (this.formData.construction_method === 2) {
                if (this.formData.contract_version === '') {
                    parent.$globalHub.$message.warning('没有工程信息，请先选择合同。');
                    return;
                }
                this.beforeOpenTablePage('order_select_construction', this.ConstructionUnitCallback);
            }
        },

        // 选择施工单位回调
        ConstructionUnitCallback(data) {
            this.formData.sgdw_id = data.ecid;
            this.formData.sgdw_code = data.construction_uscc;
            this.formData.sgdw_name = data.enterprise_name;

            this.formData.ground_floors = 0;
            this.formData.underground_floors = 0;
            this.formData.monomer = '';
            this.formData.monomer_name = '';
        },

        beforeOpening(TableCode, callbackFun) {
            if (this.formData.construction_method === 2) {
                if (this.formData.contract_version === '') {
                    this.$message.warning('没有工程信息，请先选择合同。');
                    return;
                }
                if (['order_select_monomer', 'order_select_want_material'].indexOf(TableCode) > -1) {
                    if ((this.formData.sgdw_id || '') === '') {
                        this.$message.warning('请先选择施工单位');
                        return;
                    }
                }
                this.beforeOpenTablePage(TableCode, callbackFun);
            }
        },

        openMonomerTablePage(TableCode) {
            const project_code = this.formData.project_code;
            const cvid = this.formData.contract_version;
            const bid_section_id = this.formData.section_id;
            const queryString = `project_code=${project_code}&cvid=${cvid}&bid_section_id=${bid_section_id}`;
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: TableCode,
                        QueryString: queryString,
                    },
                    selectCallback: this.selectMonomerCallback,
                },
            });
        },

        // 选择单体回调
        async selectMonomerCallback(data) {
            this.constructionFloor = [];
            this.formData.cons_floor = '';
            this.formData.ground_floors = data.ground_floors || 0;
            this.formData.underground_floors = data.underground_floors || 0;
            this.formData.monomer = data.monomer_number;
            this.formData.monomer_name = data.monomer_name;
            this.generateConstructionFloor(data.ground_floors, data.underground_floors);
            if (data.is_drawings_settlement) {
                this.formData.settlement_type = 1;
                this.formData.place_category = '105-ZT0';
                this.formData.place_category_name = '主体结构';
                this.constructionPosition = [];
                this.formData.place = '';
                this.formData.place_name = '';
                if (this.formData.monomer !== '' && this.formData.cons_floor !== '') {
                    const row = await this.getConstructionPosition();
                    if (Object.keys(row).length > 0) {
                        for (const k in row) {
                            this.constructionPosition.push({
                                name: row[k],
                                code: k,
                            });
                        }
                    }
                }
            }
        },

        // 打开选择列表
        beforeOpenTablePage(TableCode, CallbackFun) {
            const contract_version_id = this.formData.contract_version;
            const construction_id = this.formData.sgdw_id;
            const bid_section_id = this.formData.section_id;
            const queryString = `contract_version_id=${contract_version_id}&construction_id=${construction_id}&bid_section_id=${bid_section_id}`;
            this.$toast({
                title: true,
                type: 'eject',
                width: '70%',
                height: '78%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: TableCode,
                        QueryString: queryString,
                    },
                    selectCallback: CallbackFun,
                },
            });
        },

        // 选择运输方式
        selectTransportColumns() {
            const row = this.transportColumns.filter(item => item.code === this.formData.transport);
            this.formData.transport_name = row[0].name;
        },

        // 选择浇筑方式
        selectPouringOriginal() {
            const row = this.pouringOriginal.filter(item => item.code === this.formData.pouring);
            this.formData.pouring_name = row[0].name;
        },

        // 选择泵车类型
        selectPumpTruckColumns() {
            const row = this.pumpTruckColumns.filter(item => item.code === this.formData.pump_truck);
            this.formData.pump_truck_name = row[0].name;
        },

        // 选择供货站点
        selectSupplySite() {
            const row = this.supplySite.filter(item => item.code === this.formData.station_id);
            this.formData.pump_truck_name = row[0].name;
        },

        // 选择工地要料人
        async selectMaterialPerson() {
            if (this.formData.contract_version === '') {
                this.$message.warning('请先选择合同。');
                return;
            }
            if (this.formData.construction_method === 2) {
                this.beforeOpening('order_select_want_material', this.selectMaterialPersonCallbackFun);
            } else {
                let queryString = 'cvid=' + this.formData.contract_version;
                if (!this.formData.is_specify_monomer) {
                    queryString += queryString + '&engineering_number=' + this.formData.project_code;
                    this.$toast({
                        title: true,
                        type: 'eject',
                        width: '70%',
                        height: '78%',
                        t_url: 'tableList/index',
                        extr: {
                            code: {
                                TableCode: 'chose_engineeing_ylr',
                                QueryString: queryString,
                            },
                            selectCallback: this.selectMaterialPersonCallbackFun,
                        },
                    });
                } else {
                    if (this.formData.monomer === '') {
                        parent.$globalHub.$message.warning('请先选择单体。');
                        return;
                    }
                    const res = await this.getSiteMaterialBuyer();
                    if (res.length === 0) {
                        queryString += queryString + '&engineering_number=' + this.formData.project_code;
                        this.$toast({
                            title: true,
                            type: 'eject',
                            width: '70%',
                            height: '78%',
                            t_url: 'tableList/index',
                            extr: {
                                code: {
                                    TableCode: 'chose_engineeing_ylr',
                                    QueryString: queryString,
                                },
                                selectCallback: this.selectMaterialPersonCallbackFun,
                            },
                        });
                    } else {
                        queryString += queryString + '&monomer_number=' + this.formData.monomer;
                        this.$toast({
                            title: true,
                            type: 'eject',
                            width: '70%',
                            height: '78%',
                            t_url: 'tableList/index',
                            extr: {
                                code: {
                                    TableCode: 'chose_monomer_ylr',
                                    QueryString: queryString,
                                },
                                selectCallback: this.selectMaterialPersonCallbackFun,
                            },
                        });
                    }
                }
            }
        },

        // 选择工地要料人回调
        selectMaterialPersonCallbackFun(data) {
            this.formData.gdylr = data.want_material_name;
            this.formData.gdylr_phone = data.want_material_phone;
        },

        // 提交
        submitClick() {
            const results = this.requiredValidation();
            if (!results) {return;}
            this.submitLoading = true;
            this.$toast({
                title: true,
                text: '选择人员',
                type: 'eject',
                width: '10rem',
                height: '80%',
                t_url: 'selectUser/selectUser',
                viewPosition: 'components',
                extr: {
                    roleCodes: 'J01-02',
                    sureCallBack: this.salesmanCallBack,
                    cancelCallBack: this.cancelCallBack,
                },
            });
        },

        // 取消选择人员
        cancelCallBack() {
            this.submitLoading = false;
        },

        // 选择人员-回调
        async salesmanCallBack(data) {
            this.formData.sales_leader_id = data.User_Id;
            this.formData.sales_leader_name = data.User_Name;
            // 选择人员后：先调用验证管控接口
            // 1：未触发管控；2：触发管控，只提醒可以下订单；3：触发管控，不能下订单
            const controlResults = await this.getVerificationControl();
            if (controlResults.ResultCode === 1) {
                const row = await this.submitRequest();
                if (row) {
                    this.$message({
                        message: '提交成功',
                        type: 'success',
                    });
                    this.$parent.hide();
                }
            } else if (controlResults.ResultCode === 2) {
                this.$confirm(controlResults.Description, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(async () => {
                    const row = await this.submitRequest();
                    if (row) {
                        this.$message({
                            message: '提交成功',
                            type: 'success',
                        });
                        this.$parent.hide();
                    }
                });
            } else if (controlResults.ResultCode === 3) {
                this.$message({
                    message: controlResults.Description,
                    dangerouslyUseHTMLString: true,
                    type: 'warning',
                });
                this.submitLoading = false;
            }
        },


        // 初始表单数据
        getFormData(contractNumber) {
            const url = `/interfaceApi/sale/contract_manager/retail_contract/init_order?contract_number=${contractNumber}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 编辑查看初始化数据
        getInitFormData(order_number) {
            return this.$axios.$get(`/interfaceApi/production/productorder/number/${order_number}`, { defEx: true });
        },

        // 获取下拉框数据列表
        getPlaceCategory(parentCode) {
            return this.$axios.$get(`/interfaceApi/baseinfo/dictionary_config/getlist?parent_code=${parentCode}`, { defEx: true });
        },

        // 获取强度等级
        getStrengthGrade() {
            const url = `/interfaceApi/sale/contract_manager/get_strength_grade?contractNumber=${this.formData.contract_number}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 获取附加型号
        getAdditional(markcode) {
            const url = `/interfaceApi/sale/contract_manager/get_additional?contractNumber=${this.formData.contract_number}&markcode=${markcode}`;
            return this.$axios.$get(url, { defEx: true });
        },

        // 获取供货站点
        getStationList() {
            const queryStr = '?companyId=' + this.$takeTokenParameters('CompanyId') + '&branchId=' + this.$takeTokenParameters('BranchId');
            return this.$axios.$get(`/interfaceApi/baseinfo/stationmanger/get_station_list${queryStr}`, { defEx: true });
        },

        // 获取工地要料人
        getSiteMaterialBuyer() {
            return this.$axios.$get(`/interfaceApi/sale/want_materia_person/monomer_by_number/${this.formData.monomer}`, { defEx: true });
        },

        // 拉取合同是否关联经销商
        getAffiliatedDealers() {
            const url = `/interfaceApi/sale/contract_distributor/is_related_distribution_contract/${this.formData.contract_number}`;
            return this.$axios.get(url, { defEx: true });
        },

        // 选择单体，施工楼层拉取施工部位
        getConstructionPosition() {
            return this.$axios.$get(`/interfaceApi/sale/engineering_manager/monomer_Place/${this.formData.monomer}/${this.formData.cons_floor}`);
        },

        // 初始化订单编号
        getAutoCode() {
            const currentDate = util.currentTimeFormat('yyyy-MM-dd');
            return this.$axios.$get(`/interfaceApi/baseinfo/dictionary_config/getno_custom?code=P${currentDate.split('-').join('')}`);
        },

        // 验证管控
        getVerificationControl() {
            return this.$axios.$post('/interfaceApi/sale/control/retail_order_control_check', this.formData);
        },

        // 提交请求
        submitRequest() {
            return this.$axios.$post('/interfaceApi/production/productorder/retail_order/add', this.formData);
        },

        // 必填验证
        requiredValidation() {
            let verificationResults = true;
            for (const k in this.formRequired) {
                if ((this.formData[k] || '') === '') {
                    this.$message({
                        message: this.formRequired[k].message,
                        type: 'warning',
                    });
                    verificationResults = false;
                    return false;
                }
            }
            return verificationResults;
        },
    },
};
</script>
<style lang="stylus" scoped>
.retailBusinessForm_orderAgain
    height 100%
    .pageContent
        height calc(100% - 0.8rem)
        margin-bottom 0.2rem
        overflow-y auto
        padding 0.2rem
        &.fomeSee
            height 100%
        .fomeStyle_dy
            table
                margin-bottom 0.14rem
                &:last-child
                    margin-bottom 0
                th
                    width 2.2rem
                    &:before
                        color red
                td
                    width 5rem
            .el-date-editor,.el-select
                width 100% !important
            .el-radio-group
                margin-left 0.14rem
            .slump
                .el-input
                    width calc((100% - 0.4rem)/2)
                >span
                    display inline-block
                    width 0.4rem
                    text-align center
            >>>.el-input
                &.is-disabled
                    .el-input__inner
                        color #333
    footer
        text-align center
        .el-button
            width 2.2rem
            height 0.4rem
            margin 0 0.3rem
            font-size 0.16rem
</style>